import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import SeoMeta from "../components/seo";
export const CuisineGrid = props => {
  const grid = props.grid || [];
  const gridStyle = {
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',
    gridTemplateColumns: 'auto',
    gridAutoFlow: 'column',
    gridGap: '.1rem .1rem',
    marginBottom: '1rem'
  };
  const gridItemStyle = {
    border: '1px solid gray',
    padding: '.5rem'
  };
  const gHeaderStyle = {
    border: '1px solid gray',
    backgroundColor: '#ddd',
    fontWeight: '600',
    padding: '.5rem'
  };
  return <div style={gridStyle}>
      {grid.map((row, idx) => {
      return <>
          <div style={gHeaderStyle}>{row[0]}</div>
          <div style={idx !== 0 ? gridItemStyle : gHeaderStyle}>{row[1]}</div>
          <div style={idx !== 0 ? gridItemStyle : gHeaderStyle}>{row[2]}</div>
        </>;
    })}
    </div>;
};
export const FrontmatterGrid = props => {
  const {
    week
  } = props.pageContext.frontmatter;
  return <ul>
      {week.map((week, idx) => <li>
            Week {idx + 1}
            <ul>
            {Object.entries(week).map(day => <li>
                {day.reduce((a, c, i) => {
            return a + (i == 0 ? `${c.charAt(0).toUpperCase() + c.substr(1)}: ` : c);
          }, '')}
                </li>)}
            </ul>
          </li>)}
    </ul>; // let grid = [['']]
  // week.forEach((w,i) => {
  //   grid[0].push(`Week ${i}`)
  // })
  // TODO: convert object into array matrix for CuisineGrid
};
export const _frontmatter = {
  "title": "Cuisine",
  "intro": "Below is our weekly chart of the meals we will be serving.",
  "weeks": [{
    "monday": "Hot Dogs",
    "tuesday": "Hamburgers",
    "wednesday": "Spaghetti",
    "thursday": "Mac n Cheese",
    "friday": "Sandwiches"
  }, {
    "monday": "Sandwiches",
    "tuesday": "Mac n Cheese",
    "wednesday": "Spaghetti",
    "thursday": "Hamburgers",
    "friday": "Hot Dogs"
  }]
};
const layoutProps = {
  CuisineGrid,
  FrontmatterGrid,
  _frontmatter
};

const MDXLayout = function Cuisine({
  children
}) {
  return <Layout>
      <div className="container">
        {children}
      </div>
    </Layout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    {
      /* <FrontmatterGrid /> */
    }
    <SeoMeta title={props.pageContext.frontmatter.title} mdxType="SeoMeta" />
    <h3>{props.pageContext.frontmatter.title}</h3>
    <p>{props.pageContext.frontmatter.intro}</p>
    <CuisineGrid grid={[['', 'Week 1', 'Week 2'], ['Monday', 'Baked Mac & Cheese', 'Cheese & Tomato Pizza'], ['Tuesday', 'Carne Asada Tacos', 'Roast Chicken & Rice'], ['Wednesday', 'Spagetti & Boccoli', 'Spaghetti Bolognaise'], ['Thursday', 'Turkey & Cheese Sandwich', 'Grilled Cheese & Soup'], ['Friday', 'Penne Pasta', 'Chicken Nuggets & Fries']]} mdxType="CuisineGrid" />
    {
      /* This default export overrides the default layout ensuring */
    }
    {
      /*  that the FAQ component isn't wrapped by other elements */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      